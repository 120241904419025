/*!
 * Ion (https://olliecee.com)
 * Licensed under MIT (https://github.com/olliecee)
 */
 body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
}

/* Globals */
section,
footer {
  padding-top: 75px;
  padding-bottom: 75px;
}

/* Loader */
#ionic {
  width: 100%;
  height: 100%;
}

.loaderParent {
  position: relative;
  height: 100vh;
}

.loaderChild {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.loaderChild div { font-size: 1.5em }

/* Application */
header {
  background: #298fff;
  background: linear-gradient(to right, #347eff 0%, #1ea3ff 100%);
  width: 100%;
  height: auto;
  color: #ffffff;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
}

header ul li {
  margin-right: 15px;
  margin-left: 15px;
}

header ul li:first-child { margin-left: 0 }
header ul li:last-child { margin-right: 0 }

.navbar-inverse .navbar-nav .nav-link {
  color: #ffffff;
}

header .heading {
  padding-top: 75px;
  padding-bottom: 121px;
}

header img {
  width: 100%;
  height: auto;
}

header .image-vertical-center {
  position: relative;
}

header .image-vertical-center img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

header h1 {
  font-size: 48px;
  letter-spacing: -0.05em;
  line-height: 64px;
}

header h3 {
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 1.6;
  color: #d8e8ff;
}

header p {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
  font-size: 11px;
}

.heading a {
  background: white;
  color: #4a8bfc;
}

.heading a:hover,
.heading a:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  outline: none;
}

.btn:hover {
  color: #61a7ff;
}

a.btn-connect {
  color: #fff;
  background-color: #529eff;
}

a.btn-connect:hover {
  color: #fff;
  background-color: #61a7ff;
}

/* GitHub */
section.github {
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 0;
  padding-top: 30px;
  padding-bottom: 30px;
}

section.github ul {
  color: white;
  font-size: 14px;
  list-style: none;
  padding: 0;
  margin: 0;
}

section.github li {
  display: inline-block;
  margin-right: 15px;
  opacity: .6;
  letter-spacing: -.02em;
  transition: .3s opacity;
}

section.github li:hover {
  opacity: 1;
}

/* Know Me */
section.knowMe {
  background-color: #f6f7fa;
}

section.knowMe .row:last-child {
  margin-top: 65px;
}

section.knowMe svg {
  width: 100%;
  max-width: 30%;
  margin-left: -52px;
  fill-opacity: 0.93;
}

section.knowMe .react g { transform: translate(40px,40px) }
section.knowMe .reactBack { fill: #017acc }
section.knowMe .reactFore { fill: #fff }

section.knowMe svg:first-child {
  margin-left: 0;
}

section.knowMe h2 {
  line-height: 1.6em;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -.02em;
}

section.knowMe h3 {
  color: #529eff;
  font-size: 19px;
  font-weight: 500;
}

section.knowMe .quad p {
  font-size: 16px;
}

section.knowMe p {
  color: #4a566b;
  line-height: 2em;
  margin-top: 16px;
  letter-spacing: -.02em;
}

/* Fast Prototype */
section.proto {
  background-color: #ffffff;
}

section.proto .row:last-child {
  margin-top: 65px;
}

section.proto .ad img {
  width: 100%;
  max-width: 350px;
  margin-top: -150px;
}

section.proto .ad img:first-child {
  position: relative;
  z-index: 1000;
  display: block;
  margin: 0 auto;
  margin-top: -150px;
}

section.proto .ad img:last-child {
  position: absolute;
  width: 300px;
  top: 38px;
  left: -30px;
}

section.proto .wobbly {
  position: absolute;
  top: 0;
  right: 67px;
  width: 100%;
  height: auto;
}

section.proto .wobbly svg {
  width: 100%;
  height: 347px;
}

section.proto .commercial img {
  width: 100%;
  max-width: 100px;
  margin-bottom: 22px;
}

section.proto h2 {
  line-height: 1.6em;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -.02em;
}

section.proto h3 {
  color: #529eff;
  font-size: 19px;
  font-weight: 500;
}

section.proto p {
  color: #4a566b;
  line-height: 2em;
  margin-top: 16px;
  letter-spacing: -.02em;
}

section.proto a {
  text-decoration: none;
}

section.proto i {
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
}

section.proto a:hover i {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(3px, 0, 0);
}

/* Tool Kits */
section.toolkit {
  background: #09224d;
  background: linear-gradient(to right,#051d46 0,#1d3f6f 100%);
  color: #9fb0cb
}

section.toolkit .iconRevolve  {
  margin-top: 15px;
  margin-bottom: 15px;
}

section.toolkit .row:last-child {
  margin-top: 30px;
}

section.toolkit img {
  width: 100%;
  max-width: 80px;
  float: left;
  margin-right: 10px;
}

section.toolkit p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

section.toolkit h2 {
  color: #fff;
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 27px;
  letter-spacing: -.01em;
  letter-spacing: -0.02em;
}

section.toolkit h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6em;
  letter-spacing: -.02em;
}

/* Mail */
section.mail {
  background-color: #09172e;
  background: url('images/Background_Sayagata.png') repeat,linear-gradient(to right,#050f23 0,#13233f 100%);
  color: #9fb0cb;
}

section.mail .g-recaptcha {
  width: 304px;
  margin: 0 auto;
}

section.mail textarea {
  resize: none;
  height: 200px;
}

section.mail .iconRevolve  {
  margin-top: 15px;
  margin-bottom: 15px;
}

section.mail img {
  width: 100%;
  max-width: 80px;
  float: left;
  margin-right: 10px;
}

section.mail h2 {
  color: #fff;
  font-size: 27px;
  margin-bottom: 20px;
  letter-spacing: -0.02em;
}

section.mail h3 {
  font-weight: 500;
  font-size: 19px;
  line-height: 1.6em;
  margin-bottom: 20px;
}

section.mail h4 {
  color: #529eff;
  font-size: 2.5em;
  letter-spacing: -0.05em;
}

section.mail p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

section.mail aside {
  background-color: #f6f7fa;
  box-shadow: 0 8px 16px rgba(0,0,0,.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 50px;
  width: 100%;
  margin-top: -105px;
}

section.mail button.btn-primary {
  background-color: #529eff;
}

section.mail button:hover,
section.mail button:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  outline: none;
}

/* Call To */
section.callTo {
  background-color: #f6f7fa;
}

section.callTo img {
  max-width: 95px;
  width: 100%;
  float: left;
  margin-right: 17px;
}

section.callTo h2 a {
  text-decoration: none;
  font-size: 24px;
  letter-spacing: -0.02em;
}

section.callTo span {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

section.callTo h2 i {
  font-size: 18px;
  -webkit-transition: .3s transform;
  -moz-transition: .3s transform;
  -ms-transition: .3s transform;
  -o-transition: .3s transform;
  transition: .3s transform;
}

section.callTo h2 a:hover i {
  -webkit-transform: translate3d(5px, 0, 0);
  transform: translate3d(5px, 0, 0);
}

/* Resume */
.resume section,
.resume aside {
  padding-top: 65px;
  padding-bottom: 65px;
}

.resume h1 {
  color: #2079c7;
  font-size: 1.2em;
  text-transform: uppercase;
  margin-top: 55px
}

.resume section h1:first-child,
.resume aside h1:first-child {
  margin-top: 0;
}

.resume h2 {
  font-weight: bold;
}

.resume h2 span {
  font-weight: 400;
}

.resume h3 {
  font-size: 1.5em;
}

.resume .education h3 {
  margin-top: 35px;
}

.resume h4 {
  font-size: 1em;
}

/* Publication Portfolio
* Graphic Portfolio,
* Web Portfolio,
*/
section.webfolio,
section.grapholio,
section.publicolio {
  padding-top: 35px;
  padding-bottom: 35px;
}

section.webfolio img.card-img-top,
section.grapholio .card img,
section.publicolio .card img {
  width: 100%;
  height: auto;
}

/* Footer */
footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  list-style-position: inside;
}

footer ul,
footer .social li:first-child:before {
  font-size: 18px;
}

footer ul:first-child {
  display: flex;
  display: inline-flex;
  display: -webkit-inline-box;
}

footer li {
  display: inline-block;
}

footer a,
footer a:active,
footer a:hover {
  text-decoration: none;
}

footer .social {
  margin-top: -8px;
}

footer .social li {
  font-size: 26px;
  margin-right: 7px;
}

footer .social li:last-child {
  margin-right: 0;
}

footer ul li:last-child:before {
  display: none;
}

footer li:before,
footer .social:before {
  font-family: "Font Awesome 5 Free";
  content: "\f004";
  margin-right: 15px;
  margin-left: 15px;
  color: rgb(255, 126, 126);
}

footer .social li:before,
footer li:first-child:before {
  content: "";
  margin-right: 0;
  margin-left: 0;
}

footer .ollie-contact {
  text-align: right;
}

footer span {
  font-size: 15px;
  letter-spacing: -0.02em;
}

footer hr {
  border: none;
  height: 1px;
  background: #ff3535;
  background: -moz-linear-gradient(left, #ff3535 0%, #ffb22d 19%, #f7df00 37%, #26ff6c 56%, #268bff 76%, #8b3cff 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ff3535), color-stop(19%,#ffb22d), color-stop(37%,#f7df00), color-stop(56%,#26ff6c), color-stop(76%,#268bff), color-stop(100%,#8b3cff));
  background: -webkit-linear-gradient(left, #ff3535 0%,#ffb22d 19%,#f7df00 37%,#26ff6c 56%,#268bff 76%,#8b3cff 100%);
  background: -o-linear-gradient(left, #ff3535 0%,#ffb22d 19%,#f7df00 37%,#26ff6c 56%,#268bff 76%,#8b3cff 100%);
  background: -ms-linear-gradient(left, #ff3535 0%,#ffb22d 19%,#f7df00 37%,#26ff6c 56%,#268bff 76%,#8b3cff 100%);
  background: linear-gradient(to right, #ff3535 0%,#ffb22d 19%,#f7df00 37%,#26ff6c 56%,#268bff 76%,#8b3cff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3535', endColorstr='#8b3cff',GradientType=1 );
  width: 200px;
  margin: 8px 0 8px auto;
}

/* col-md-^ */
@media screen and (max-width:991px) {
  button.navbar-toggler {
      top: 25px;
      background: rgba(255,255,255,0.15);
  }

  header .heading {
      padding-top: 25px;
  }

  header .navbar-nav .nav-link {
      text-align: right;
  }

  header ul li {
      margin: 0
  }

  section.mail aside {
      margin-top: 55px
  }

  section.callTo .col-md-12:last-child {
      margin-top: 30px
  }

  footer .row > div {
      text-align: center
  }

  footer ul {
      line-height: 2.5em;
  }
}

/* col-sm-^ */
@media screen and (max-width:768px) {
  header h1 {
      line-height: 50px;
      font-size: 40px;
  }

  section.proto .commercial .col-md-4 {
      margin-top: 55px;
  }

  section.proto .commercial .col-md-4:first-child {
      margin-top: 0;
  }
}